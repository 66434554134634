import { User } from "auth0";
import {
  CRMUserMetadata,
  empty,
  isset,
  MemberType,
  nexstarAccessLevel,
  objectFieldValidator,
  UserAccessLevels,
} from "@nexstar-network/shared-utils";

export interface CRMUser extends User {
  "https://nexstarcrm.com/app_metadata": CRMUserMetadata;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  sub: string;
}
function getUserMetadata(user: CRMUser): CRMUserMetadata {
  if (
    user &&
    "https://nexstarcrm.com/app_metadata" in user &&
    user["https://nexstarcrm.com/app_metadata"]
  )
    return user["https://nexstarcrm.com/app_metadata"];
  return null;
}
export const noUserInProd = (user: CRMUser): boolean => {
  if (process.env.REACT_APP_NODE_ENV !== "production") return false;
  return !(
    process.env.REACT_APP_NODE_ENV === "production" &&
    (user !== undefined || user !== null)
  );
};

export function AccessLevelIdOrDefault(user: CRMUser): number {
  const userMetadata = getUserMetadata(user);
  let roleId = objectFieldValidator(userMetadata, ["gid"])
    ? userMetadata.gid
    : null;
  if (roleId === null && process.env.REACT_APP_NODE_ENV === "development") {
    roleId = 1;
  }
  if (roleId === null && process.env.React_APP_NODE_ENV !== "development") {
    roleId = 7;
  }
  return roleId;
}

// TP-1881: changed to be based off CRM roles
export function isNexstarEmployee(user: CRMUser) {
  return nexstarAccessLevel(AccessLevelIdOrDefault(user));
}

// TP-1881: replaces all checks for FS, BPW, PR and overheads
export const userCanAccessFinancials = (user: CRMUser): boolean => {
  const userMetadata = getUserMetadata(user);
  const userAccessLevel = AccessLevelIdOrDefault(user);
  if (
    process.env.React_APP_NODE_ENV === "development" ||
    userAccessLevel === UserAccessLevels.SITE_ADMIN ||
    isNexstarEmployee(user)
  )
    return true;
  if (
    userMetadata.canAccessFinancials === null &&
    process.env.REACT_APP_NODE_ENV === "development"
  )
    return true;
  if (
    userMetadata.canAccessFinancials === null &&
    process.env.React_APP_NODE_ENV !== "development"
  )
    return false;
  return !!userMetadata.canAccessFinancials;
};

export const nicknameOrDefault = (user: CRMUser): string => {
  const userMetadata = getUserMetadata(user);
  if (!userMetadata) return "Local Host";
  return user.nickname;
};

export const avatarOrDefault = (user: CRMUser): string => {
  if (
    !user ||
    (user.picture === null && process.env.REACT_APP_NODE_ENV !== "production")
  )
    return "https://portal.nexstarnetwork.com/uploads/images/avatar.png";
  if (process.env.REACT_APP_NODE_ENV === "production" && user.picture !== null)
    return user.picture;
  return "https://portal.nexstarnetwork.com/uploads/images/avatar.png";
};

// Leaving for future use
// export const companyOrDefault = (user: CRMUser): string => {
//   const userMetadata = getUserMetadata(user);
//   if (!userMetadata || userMetadata.company === null) return 'Nexstar Network, Inc.';
//   return userMetadata.company;
// };

export const CountryOrDefault = (
  user: CRMUser,
  selectedCompany?: MemberType,
): string => {
  const userMetadata = getUserMetadata(user);
  let country =
    userMetadata && userMetadata.companyCountry !== null
      ? userMetadata.companyCountry
      : null;
  if (country === null && process.env.REACT_APP_NODE_ENV !== "production") {
    country = "United States";
  }
  // TP-97 CAS removed isCoach so it returns correct data
  if (
    selectedCompany &&
    selectedCompany !== undefined &&
    selectedCompany.companyCountry !== null
  ) {
    return selectedCompany.companyCountry;
  }
  return country;
};
export const UserIdOrDefault = (user: CRMUser): number => {
  const userMetadata = getUserMetadata(user);
  let userId = userMetadata ? userMetadata.uid : null;
  if (userId === null && process.env.REACT_APP_NODE_ENV !== "production") {
    userId = 45057;
  }
  return userId;
};
// TP-690 JAH: Needed to get the ProfileId for the Owners endpoint
// export const ProfileIdOrDefault = (user: CRMUser): number => {
//   const userMetadata = getUserMetadata(user);
//   let profileId = userMetadata ? userMetadata.pid : null;
//   if (
//     (profileId === null || profileId === undefined) &&
//     process.env.REACT_APP_NODE_ENV !== "production"
//   ) {
//     profileId = 123;
//   }
//   return profileId;
// };
export const UserNameOrDefault = (user: CRMUser): string => {
  const userMetadata = getUserMetadata(user);
  if (!userMetadata || !userMetadata.companyCountry) return "Default User";
  return `${userMetadata.userFirst} ${userMetadata.userLast}`;
};

export const CompanyIdOrDefault = (
  user: CRMUser,
  selectedCompany?: MemberType,
): number => {
  const userMetadata = getUserMetadata(user);
  let companyId = userMetadata ? userMetadata.cid : null;
  if (companyId === null && process.env.REACT_APP_NODE_ENV === "development") {
    companyId = 372;
  }
  // TP-97 CAS removed isCoach so it returns correct data
  // T.P-255: JAH Needed to keep checks so nothing is overwritten
  if (!empty(selectedCompany)) {
    companyId = selectedCompany.companyId;
  }
  // return 3160;
  // return 808;
  // return 404;
  // return 226;
  if (!empty(process.env.REACT_APP_CYPRESS)) {
    companyId = parseInt(process.env.REACT_APP_CYPRESS, 10);
  }
  return companyId;
};

export const CompanyNameOrDefault = (
  user: CRMUser,
  selectedCompany?: MemberType,
): string => {
  const userMetadata = getUserMetadata(user);
  let companyName = userMetadata ? userMetadata.companyName : null;
  if (companyName === null && process.env.REACT_APP_NODE_ENV !== "production") {
    companyName = "Nexstar Network";
  }
  if (
    selectedCompany &&
    selectedCompany !== undefined &&
    selectedCompany.companyName !== null
  ) {
    companyName = selectedCompany.companyName;
  }
  return companyName;
};

//TODO: Finalize Implementation of this.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function FunctionalRoleIdOrDefault(user: CRMUser): number {
  return null;
}
export const locationOrDefault = (
  user: CRMUser,
  selectedCompany?: MemberType,
): string => {
  const userMetadata = getUserMetadata(user);
  let companyZipCode = userMetadata ? userMetadata.companyZipCode : null;
  if (
    companyZipCode === null &&
    process.env.REACT_APP_NODE_ENV === "development"
  ) {
    companyZipCode = "55101";
  }
  // TP-97 CAS removed isCoach so it returns correct data
  if (
    selectedCompany &&
    selectedCompany !== undefined &&
    selectedCompany.companyZipCode !== null
  ) {
    companyZipCode = selectedCompany.companyZipCode;
  }
  return `${companyZipCode},${CountryOrDefault(user, selectedCompany)}`;
};

export const celsiusOrFahrenheit = (
  user: CRMUser,
  selectedCompany?: MemberType,
): string => {
  let metric = "F";
  if (
    CountryOrDefault(user, selectedCompany) !== "United States" &&
    CountryOrDefault(user, selectedCompany) !== "US"
  ) {
    metric = "C";
  }
  return metric;
};

export const primaryCompany = (user: CRMUser, getUser = false): any => {
  const userMetadata = getUserMetadata(user);
  let companyObject = {};
  const sessionCompany = JSON.parse(
    window.sessionStorage.getItem("userSelectedCompany"),
  );
  if (sessionCompany && !getUser) {
    companyObject = sessionCompany;
  } else if (userMetadata && process.env.REACT_APP_NODE_ENV !== "development") {
    companyObject = {
      companyName: userMetadata.companyName,
      companyId: userMetadata.companyID,
      companyCountry: userMetadata.companyCountry,
      companyZipCode: userMetadata.companyZipCode,
      companyCity: userMetadata.companyCity,
      companyState: userMetadata.companyState,
      companyLatitude: userMetadata.companyLatitude,
      companyLongitude: userMetadata.companyLongitude,
    };
  } else {
    companyObject = {
      companyName: "Nexstar Network, Inc.",
      companyId: 372,
      companyCountry: "United States",
      companyZipCode: 55435,
      companyCity: "Bloomington",
      companyState: "Minnesota",
      companyLatitude: 44.8613,
      companyLongitude: -93.3315,
    };
  }
  return companyObject;
};
export const CoachCanAccessAdmin = (user: CRMUser): boolean => {
  const allowedProfiles = [
    581, // Jodi Peter
    586, // Shane Rau
    579, // Jamie Robinson
    9550, // Brittany Spencer
    580, // Jeff Stagnoli
    584, // Scott Pearson
    578, // Gresham Ard
  ];
  return allowedProfiles.includes(UserIdOrDefault(user));
};
export const CanAccessAdminPage = (user: CRMUser): boolean =>
  AccessLevelIdOrDefault(user) === 1;

/**
 * Create Created By String to store.
 *
 * @param user User
 *
 * @returns string
 */
export const UpdatedByString = (user: CRMUser): string =>
  `${UserIdOrDefault(user)}#${UserNameOrDefault(user)}#${CompanyIdOrDefault(
    user,
  )}`;

export const CompanyLatitude = (
  user: CRMUser,
  selectedCompany?: MemberType,
): string => {
  const userMetadata = getUserMetadata(user);
  let latitude = isset(userMetadata?.companyLatitude)
    ? userMetadata.companyLatitude
    : "44.9476";
  if (
    selectedCompany &&
    selectedCompany !== undefined &&
    selectedCompany.companyLatitude !== null
  ) {
    latitude = selectedCompany.companyLatitude;
  }
  return latitude.toString();
};

export const CompanyLongitude = (
  user: CRMUser,
  selectedCompany?: MemberType,
): string => {
  const userMetadata = getUserMetadata(user);
  let longitude = isset(userMetadata?.companyLongitude)
    ? userMetadata.companyLongitude
    : "-93.0919";
  if (
    selectedCompany &&
    selectedCompany !== undefined &&
    selectedCompany.companyLongitude !== null
  ) {
    longitude = selectedCompany.companyLongitude;
  }
  return longitude.toString();
};
